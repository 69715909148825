.audio-input-animation {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .timer {
    margin-left: 10px;
  }
  
  .mic-icon {
    cursor: pointer;
  }
  
  .soundwave {
    display: flex;
    justify-content: space-between;
    width: 110px;
  }
  
  .wave {
    width: 5px;
    height: 20px;
    background: #3498db;
    border-radius: 4px;
    animation: soundwave 1.2s infinite ease-in-out;
    transform-origin: center; 
  }
  
  /* Static bars when not listening */
  .not-listening .wave {
    transform: scaleY(1); /* Set to a smaller size */
    animation: none; /* No animation */
  }
  
  /* Animated bars when listening */
  .listening .wave {
    animation: soundwave 1.7s infinite ease-in-out;
  }
  
  .listening .wave:nth-child(2) {
    animation-delay: -1.6s;
  }
  
  .listening .wave:nth-child(3) {
    animation-delay: -1.5s;
  }

  .listening .wave:nth-child(4) {
    animation-delay: -1.4s;
  }

  .listening .wave:nth-child(5) {
    animation-delay: -1.3s;
  }

  .listening .wave:nth-child(6) {
    animation-delay: -1.2s;
  }

  .listening .wave:nth-child(7) {
    animation-delay: -1.1s;
  }

  .listening .wave:nth-child(8) {
    animation-delay: -1.0s;
  }

  .listening .wave:nth-child(9) {
    animation-delay: -0.9s;
  }

  .listening .wave:nth-child(10) {
    animation-delay: -0.8s;
  }

  .listening .wave:nth-child(11) {
    animation-delay: -0.7s;
  }

  .listening .wave:nth-child(12) {
    animation-delay: -0.6s;
  }

  .listening .wave:nth-child(13) {
    animation-delay: -0.5s;
  }

  .listening .wave:nth-child(14) {
    animation-delay: -0.4s;
  }

  .listening .wave:nth-child(15) {
    animation-delay: -0.3s;
  }
  
  @keyframes soundwave {
    0%, 100% {
      transform: scaleY(0.3); /* Small size at start and end */
    }
    50% {
      transform: scaleY(1); /* Full size at the middle of the animation */
    }
  }
  